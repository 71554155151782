import * as http from '@/api/http'
/**
 * 获取url参数
 * @param key 可选
 * @param url 可选
 */
export const getUrlParams = (key, url) => {
  url = url || window.location.href
  const params = {}
  url.replace(/([^?&]+)=([^?&]+)/g, (s, v, k) => {
    params[decodeURIComponent(v)] = decodeURIComponent(k)
    return k + '=' + v
  })
  return (key ? params[key] : params) || ''
}

/**
 * 空判断
 * @param args
 * @return {boolean}
 */
export const isEmpty = args => args === null || args === undefined || args === ''

/**
 * 空对象验证
 * @param obj
 * @returns {boolean}
 */
export const isEmptyObject = obj => {
  if (!obj) return true
  let t
  for (t in obj) return false
  return true
}

/**
 * 获取临时token
 */
export const getTemToken = () => {
  return 'L' + new Date().getTime() + getRandomString()
}

/**
 * 获取6位随机数
 * @returns {number}
 */
export const getRandomString = () => {
  let mm = Math.random()
  let six = null
  if (mm > 0.1) {
    six = Math.round(mm * 1000000)
  } else {
    mm += 0.1
    six = Math.round(mm * 1000000)
  }
  return six
}
/**
 * 去除左右空格
 */
export const trim = str => {
  if (!str || typeof str !== 'string') return str
  return str.replace(/^\s+|\s+$/g, '')
}

/**
 * 判断是否数字
 */
export const isNumber = args => /^[0-9]*$/.test(trim(args))

//过滤表情
export const checkEmojiRule = value => {
  const emojiRule = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
  return emojiRule.test(value)
}
//富文本上传图片
export const uploadImg = async (file, business, url = '/my/file/upload') => {
  try {
    const formdata = new FormData()
    formdata.append('files', file)
    if (business) {
      formdata.append('business', business)
    }
    return await http.post(url, formdata)
  } catch (err) {
    return err
  }
}
export const checkMobile = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入手机号'))
  } else {
    const reg = /^1\d{10}$/
    if (reg.test(value)) {
      callback()
    } else {
      return callback(new Error('手机号格式错误'))
    }
  }
}
export const checkPhone = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入手机号或座机号'))
  } else {
    const reg = /^((0\d{2,3}-\d{7,8})|(1\d{10}))$/
    if (reg.test(value)) {
      callback()
    } else {
      return callback(new Error('格式错误'))
    }
  }
}
