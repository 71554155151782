<template>
  <el-row class="container" v-loading="loading">
    <!--头部-->
    <el-col :span="24" class="topbar">
      <div class="topbar-logo">
        <img @click="jumpTo('/')" src="@/assets/images/logo.png" width="99px" />
      </div>
      <!-- 顶部顶级导航菜单 -->
      <div class="topbar-nav">
        <div v-for="(item, index) in menu_head" :key="index" :class="item.id == defaultMenuId ? 'topbar-nav-item topbar-nav-active' : 'topbar-nav-item'" @click="getLeftMenu(item.id)">
          <i class="iconfont" :class="item.icon"></i>{{ item.name }}
        </div>
      </div>
      <!-- 个人信息菜单 -->
      <div class="topbar-account">
        <el-dropdown trigger="click">
          <span class="topbar-account-userinfo">
            <i class="el-icon-user"></i>
            {{ realName }}
            <i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-col>

    <!--中间-->
    <el-col :span="24" class="main">
      <!--左侧导航-->
      <aside class="main-left">
        <!--展开折叠开关-->
        <div class="menu-toggle" @click.prevent="collapse">
          <i class="el-icon-s-fold" v-show="!collapsed"></i>
          <i class="el-icon-s-unfold" v-show="collapsed"></i>
        </div>
        <!--导航菜单-->
        <el-menu :collapse="collapsed" background-color="#545c64" text-color="#fff" class="el-menu-vertical-demo" router>
          <el-submenu v-for="(item, index) in this.menu_left" :key="index" :index="index + ''">
            <template slot="title">
              <i class="iconfont" :class="item.icon"></i>
              <span>{{ item.name }}</span>
            </template>
            <el-menu-item v-for="(items, indexs) in item.children" :key="indexs" :index="items.code" :class="$route.path == items.code ? 'active' : ''">
              <span slot="title">
                {{ items.name }}
              </span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </aside>

      <!--右侧内容区-->
      <section class="main-right">
        <el-col :span="24" class="content-wrapper">
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </el-col>
      </section>
    </el-col>
  </el-row>
</template>

<script>
import { bus } from '@/utils/bus.js'
import _ from 'lodash'
import { ssoLogin, logout, getMyMenu, myInfo } from '@/api/api_public'
export default {
  name: 'home',
  data() {
    return {
      loading: false,
      defaultMenuId: 0,
      realName: '',
      collapsed: false,
      navList: [],
      menu_head: [],
      menu_left: []
    }
  },
  created() {
    bus.$on('login', () => {
      this.Login()
    })
    bus.$on('showLoading', () => {
      this.loading = true
    })
    bus.$on('hideLoading', () => {
      this.loading = false
    })
    // this.getMenu()
    this.getUserAndNavInfo()
  },
  mounted() {},
  methods: {
    async getUserAndNavInfo() {
      let token = this.$route.query.token || localStorage.token
      if (token) {
        localStorage.setItem('token', token)
        // this.getUserInfo()
        this.getMenu()
        this.getMyInfo()
      } else {
        this.Login()
      }
    },
    async Login() {
      let { code, data } = await ssoLogin({})
      if (code === '000000') {
        window.location.href = data.url
      }
      /* if (res.ok && res.data.isLogin === 1) {
        const redirect_uri = encodeURIComponent(res.data.redirectUri)
        let href = window.location.href.includes('?') > 0 ? window.location.href + '&s=O' : window.location.href + '?s=O'
        let target_uri = this.$route.query.userId ? encodeURIComponent(process.env.VUE_APP_URL + '?s=O') : encodeURIComponent(href)
        window.location.href = process.env.VUE_APP_4ALOGINURL + res.data.clientId + '&redirect_uri=' + redirect_uri + '&target_uri=' + target_uri + '&response_type=' + res.code
      } else if (res.ok && res.data.isLogin === 0) {
        this.$router.replace('/speciallogin')
      } */
    },
    async getMenu() {
      let params = {}
      let res = await getMyMenu(params)
      if (res && res.code === '000000') {
        this.menu_head = res.data.children
        this.defaultMenuId = Number(localStorage.getItem('selectTopNavId')) || this.menu_head[0].id
        this.getLeftMenu(this.defaultMenuId)
      }
    },
    async getMyInfo() {
      let params = {}
      let res = await myInfo(params)
      if (res && res.code === '000000') {
        this.realName = res.data.realName ? res.data.realName : res.data.mobile
      }
    },
    //获取左侧菜单
    getLeftMenu(id) {
      this.defaultMenuId = id
      localStorage.setItem('selectTopNavId', id)
      this.menu_head.forEach(e => {
        if (e.id === this.defaultMenuId) {
          this.menu_left = _.cloneDeep(e.children)
        }
      })
    },
    //折叠导航栏
    collapse() {
      this.collapsed = !this.collapsed
    },
    jumpTo(url) {
      this.$router.push(url) //用go刷新
    },

    logout() {
      this.$confirm('确认退出吗?', '提示', {
        confirmButtonClass: 'el-button--warning'
      }).then(() => {
        logout().then(result => {
          if (result.code === '000000') {
            localStorage.clear()
            window.location.href = 'https://tjsso.tjos.com/login'
          }
          /* if (result.data.loginType === 1) {
            window.location.href = process.env.VUE_APP_4AURL
          } else {
            this.$router.replace('/speciallogin')
          } */
        })
      })
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/*滚动条样式*/
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgb(84, 92, 100);
  border-radius: 0;
  background: rgb(84, 92, 100);
}
.container {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  .topbar {
    display: flex;
    height: 60px;
    background: #3e8ef7;
    &-logo {
      box-sizing: border-box;
      width: 200px;
      padding: 15px 0 0 20px;
      cursor: pointer;
    }
    &-nav {
      display: flex;
      align-items: stretch;
      margin-left: 20px;
      &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        line-height: 60px;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        .iconfont {
          font-size: 16px;
          margin-right: 5px;
        }
      }
      &-item:hover {
        background-color: #3780dd;
      }
      &-active {
        background: #3780dd;
        font-weight: bold;
        .iconfont {
          font-weight: normal;
        }
      }
    }
    &-account {
      position: absolute;
      top: 0;
      right: 0;
      &-userinfo {
        padding: 0 15px;
        height: 60px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #fff;
        i {
          margin: 0 5px;
        }
      }
    }
  }
  .main {
    display: flex;
    position: absolute;
    top: 60px;
    bottom: 0px;
    overflow: hidden;
    &-left {
      max-width: 200px;
      min-width: 60px;
      background: rgb(84, 92, 100);
      overflow: hidden;
      .menu-toggle {
        height: 16px;
        text-align: center;
        color: #fff;
        padding: 5px 0;
        cursor: pointer;
      }
      .el-menu-vertical-demo {
        width: 200px;
        height: calc(100% - 30px);
        overflow-y: scroll;
      }
      .el-menu--collapse {
        width: 60px;
        border-right: none;
        overflow: hidden;
      }
      .el-menu-item.is-active {
        color: #fff;
      }
      .el-menu-item.active {
        color: $blue !important;
      }
      .iconfont {
        font-size: 14px;
        margin-right: 5px;
        color: #fff;
      }
    }
    &-right {
      background: #f1f4f5;
      flex: 1;
      overflow-y: auto;
      padding: 20px;
      .content-wrapper {
        padding: 20px;
        background-color: #fff;
        box-sizing: border-box;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
}
</style>
