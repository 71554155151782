/* 存放公共API */

import * as http from './http'

//登录
export const login = params => http.post('/public/auth/login', params)
export const ssoLogin = params => http.post('/public/sso/login-info', params)
export const secret = params => http.get('/public/auth/secret', params, { loading: false, interceptRes: false })
export const getCode = params => http.post('/public/auth/verify-code', params)
export const getImgCode = params => http.post('/public/auth/img-code', params)
//登出
export const logout = params => http.post('/logout', params)
//登录User信息
export const myInfo = params => http.post('/my/info', params)
export const getLoginInfo = params => http.post('/getLoginInfo', params)

export const userInfo = params => http.post('/adminUser/userInfo', params)

//菜单
export const getMyMenu = params => http.post('/my/menu', params)

//上传文件、图片接口[多文件]
export const uploadFile = params => http.post('/my/file/upload', params)
