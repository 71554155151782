import Vue from 'vue'

/**
 * 过滤表情指令
 */
Vue.directive('filteremoji', {
  bind: function(el) {
    el = el.getElementsByTagName('input')[0]
    el.addEventListener('input', () => {
      if (el.value) {
        el.value = el.value.replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g, '')
      }
    })
  }
})
