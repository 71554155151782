/**
 * http请求封装
 */
import axios from 'axios'
import router from '../router'
import { bus } from '@/utils/bus.js'
import { message as Message } from '../utils/resetmessage'
import { isEmptyObject } from '../utils/index'
const c = 1
const p = 2
let t = new Date().getTime()
/**
 * 获取头信息
 */
const getHeaders = (options = {}) => {
  // header头参数设置
  const defaultHeaders = {
    c,
    p,
    t,
    token: localStorage.getItem('token') || ''
  }
  if (options && options.headers && typeof options.headers === 'object') {
    const headers = options.headers
    for (const key in headers) {
      if (key && Object.prototype.hasOwnProperty.call(headers, key)) {
        defaultHeaders[key] = headers[key]
      }
    }
  }
  return defaultHeaders
}

/**
 * 请求
 * @param method
 * @param url
 * @param params
 * @param options
 * @returns {Promise<any>}
 */
const request = (method, url, params, options = {}) => {
  return new Promise((resolve, reject) => {
    params = params || {}
    options.headers = getHeaders(options)
    options = Object.assign(
      {
        url: '', // 请求地址
        method: 'get', // 请求方法类型
        baseURL: process.env.VUE_APP_BASEURL,
        timeout: 100000, // 请求超时时间（毫秒）40s
        headers: {}, // 头信息
        localUrl: false, // 是否本地url，false: 根据配置文件 url会被重写, true: url保持原样不变
        params: null, // 参数(get类型)
        data: null, // 参数(body类型)
        interceptRes: true, // 是否开启统一错误处理
        isEncrypt: false, // 是否加密传输数据
        cache: false, // 是否开启缓存
        reportProgress: false, // 是否开启进度条
        fileName: '', // 文件名
        loading: true, // 是否开启loading状态
        withCredentials: false, // 是否携带cookie信息
        responseType: 'json' // 响应格式: 可选项 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
      },
      options,
      {
        method,
        url
      }
    )
    if (method === 'delete' || method === 'get') {
      if (!isEmptyObject(params)) {
        options.params = params
        options.params.t = t
      }
    } else {
      options.data = params
      options.data.t = t
    }
    if (options.loading) {
      bus.$emit('showLoading')
    }
    axios
      .request(options)
      .then(response => {
        bus.$emit('hideLoading')
        // 是否对返回结果统一处理
        if (options.interceptRes) {
          let result = response.data
          if (result && result.code === '000000') {
            return resolve(result)
          } else if (result && result.code !== '000000') {
            // 处理各种code码错误情况和登录过期等问题
            if (result.code === '000401') {
              bus.$emit('login')
            } else if (result.code === '001103') {
              Message.error({ showClose: true, message: result.msg || '帐号已在其他地方登录，请注意帐号安全', duration: 3000 })
              bus.$emit('login')
            } else if (result.code === '000404') {
              router.push({ path: '/error404' })
            } else {
              Message.error({ showClose: true, message: result.msg, duration: 2000 })
              return resolve(result)
            }
          } else if (!result) {
            Message.error({ showClose: true, message: '请求错误,未返回数据', duration: 2000 })
            return resolve(result)
          }
        } else {
          return resolve(response.data)
        }
      })
      .catch(err => {
        bus.$emit('hideLoading')
        if (String(err).includes('timeout')) {
          Message.error({ showClose: true, message: '请求超时，请重新刷新', duration: 2000 })
        } else {
          router.push({ path: '/error500' })
        }
        reject(err)
      })
  })
}

/**
 * 方法
 * @param url 接口地址
 * @param params 参数 [可选]
 * @param options 参数 [可选]
 * @returns {Promise}
 */
export const get = (url, params, options) => request('get', url, params, options)
export const post = (url, params, options) => request('post', url, params, options)
export const patch = (url, params, options) => request('patch', url, params, options)
export const put = (url, params, options) => request('put', url, params, options)
export const del = (url, params, options) => request('delete', url, params, options)

/*export const DOWN = (url, name, method, param) => {
  if (!url || !name) return
  axios({
    method: method ? method : 'get',
    url: `${url}?name=` + name,
    responseType: 'arraybuffer',
    data: param
  }).then(res => {
    const url = window.URL.createObjectURL(new Blob([res.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name) //or any other extension
    document.body.appendChild(link)
    link.click()
  })
}

//导出Excel
export const POSTExcel = (url, params) => {
  return API.POST(url, params, { responseType: 'blob' })
    .then(res => res.data)
    .catch(error => {
      bus.$emit('hideLoading')
      console.log(error)
    })
}
 */
