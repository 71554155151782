import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import ElTreeGrid from 'element-tree-grid'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/iconfont/iconfont.css'
import '@/assets/css/main.scss'
import '@/directives/index.js'

Vue.use(ElementUI)
Vue.component(ElTreeGrid.name, ElTreeGrid)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
