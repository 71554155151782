import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const system = [
  /* 菜单管理and角色管理 */
  { path: 'system/rbac/menu', component: () => import('../views/system/rbac/menu.vue'), name: '菜单管理' },
  { path: 'system/rbac/role', component: () => import('../views/system/rbac/role.vue'), name: '角色管理' },
  /* 消息管理 */
  { path: 'system/message/template', component: () => import('../views/system/message/template.vue'), name: '消息模板' },
  { path: 'system/message/sms', component: () => import('../views/system/message/sms.vue'), name: '消息发送记录' },
  /* APP版本管理 */
  { path: 'system/version/list', component: () => import('../views/system/version/list.vue'), name: '幸福绩效版本管理' },
  { path: 'system/version/threeList', component: () => import('../views/system/version/threeList.vue'), name: '三清宝版本管理' },
  /* 敏感词库 */
  { path: 'system/sensitivewords/list', component: () => import('../views/system/sensitivewords/list.vue'), name: '敏感词库管理' },
  /* 操作员管理 */
  { path: 'system/adminuser/list', component: () => import('../views/system/adminuser/list.vue'), name: '操作员管理' },
  /* 操作日志 */
  { path: 'system/log/operationlog', component: () => import('../views/system/log/operationlog.vue'), name: '操作日志管理' },
  /* 协议管理 */
  { path: 'system/agreement/list', component: () => import('../views/system/agreement/list.vue'), name: '协议管理' },
  /* 字典管理 */
  { path: 'system/dictionary/list', component: () => import('../views/system/dictionary/list.vue'), name: '字典管理' },
  /* 意见反馈 */
  { path: 'system/suggestion/list', component: () => import('../views/system/suggestion/list.vue'), name: '意见反馈' },
  /* 配置中心 */
  { path: 'system/config/config', component: () => import('../views/system/config/config.vue'), name: '配置管理' },
  { path: 'system/config/project', component: () => import('../views/system/config/project.vue'), name: '项目管理' }
]
const operate = [
  /* 企业账号管理 */
  { path: 'operate/enterprise/admin', component: () => import('../views/operate/enterprise/admin.vue'), name: '企业账号管理' }
]
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/dashboard',
    children: [{ path: '/dashboard', component: () => import('../views/Dashboard.vue'), name: '仪表盘' }].concat(system, operate)
  },
  {
    path: '*',
    redirect: '/dashboard'
  },
  {
    path: '/speciallogin',
    name: 'SpecialLogin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/error404',
    name: 'Error404',
    component: () => import(/* webpackChunkName: "error" */ '../views/404.vue'),
    meta: {
      title: '404'
    }
  },
  {
    path: '/error500',
    name: 'Error500',
    component: () => import(/* webpackChunkName: "error" */ '../views/500.vue'),
    meta: {
      title: '服务器异常'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
